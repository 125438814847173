import React from 'react'
import {Headline as HeadlineWrapless} from '@components/headline'
import GridContainer from '@components/gridContainer'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'

import './downloadList.scss'

const DownloadListItem = (props) => {
  function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}
  let downloadSize = formatBytes(props.file.filesize)
  return (
      <div className="downloadList-item" key={props.i}>
        <div className="downloadList-itemName">{props.name}</div>
        <div className="downloadList-itemFile">
          <div className="downloadList-itemFileType">{props.file.subtype}</div>
          <div className="downloadList-itemFileSize">{downloadSize}</div>
        </div>
        <div className="downloadList-itemControl"><a href={props.src} className="downloadList-itemControlLink" download><DownloadIcon className="downloadList-downloadIcon"/></a></div>
      </div>
  )
}

//const test = `DownloadList${props.modifier ? ' downloadList--' + props.modifier : ''}`

export const DownloadList = (props) => {
  return (
    <>
      <div className={`downloadList${props.modifier ? ` downloadList--${props.modifier}` : ''}`} >
        <HeadlineWrapless priority="2" modifier="headline--h2" text="Downloads" />
        {props.richtext &&
          <div className="downloadList-richtext">{props.richtext}</div>
        }
        {props.downloadListItems.map((downloadItem, index) => (
          <DownloadListItem key={index} name={downloadItem.title} file={downloadItem.file} src={downloadItem.file.url}/>
        ))}
      </div>
    </>
  )
}

export default GridContainer(DownloadList)
