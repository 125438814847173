import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import ScrollTop from "@shared/utilities/scroll-top";
import Routes from "@shared/app/routes";
import store from "@shared/app/store";
import { flatten } from "@shared/utilities/helper";

// TODO: DO?

export const getPageFromSitemap = (id, field = "id", lang = false) => {
  const language =
    lang === false ? store.getState().preferences.language : lang;
  const sitemap = store.getState().sitemap;
  const pages = flatten(sitemap[language]);

  const page = pages.find((page) => page[field] === id);
  return page !== undefined ? page : false;
};

export const t = (key, defaultLanguage = `en`) => {
  const preferences = store.getState().preferences;
  const language = preferences.language;
  const translations = preferences.translations;

  if (translations[language]) {
    if (translations[language]) {
      if (translations[language][key]) return translations[language][key];
    }
    return translations[defaultLanguage][key]
      ? translations[defaultLanguage][key]
      : key;
  }
  return key;
};

const BrandPlatform = (props) => {
  return (
    <Router basename={props.config.basename}>
      <ScrollTop />
      <Provider store={store}>
        <Routes children={props.children} />
      </Provider>
    </Router>
  );
};

export default BrandPlatform;
