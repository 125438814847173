import React from 'react';
import GridContainer from '@components/gridContainer'
import Masonry from 'react-masonry-css'
import { Image as ImageWrapless } from "@components/image";

import './masonryGallery.scss'

export const MasonryGallery = (props) => {
  const items = props.images
  const masonryItems = items.map(function(item, index) {
    return (
      <div className="masonryGallery-item" key={index}>
        <ImageWrapless src={item.image} modifier="masonryGallery-image" caption={item.caption} border={item.addborder}></ImageWrapless>
      </div>
    )
  });

  return (
    <>
      <div className={`masonryGallery${props.modifier ? ` ${props.modifier}` : ''}`}>
        <Masonry
          breakpointCols={props.columns}
          className="masonryGallery-grid"
          columnClassName="masonryGallery-column"
          >
          {masonryItems}
        </Masonry>
      </div>
    </>
  )
}

export default GridContainer(MasonryGallery)
