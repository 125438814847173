export const flatten = (array, parent = 0, level = 0) => {
  let result = [];
  array.forEach((a) => {
    result.push(
      Object.assign({}, a, {
        parent: parent,
        level: level,
      })
    );
    if (a.children.length > 0) {
      result = result.concat(flatten(a.children, a.id, level + 1));
    }
  });
  return result;
};

export const findParent = (node, array) => {
  let result = [node];
  if (node.parent) {
    const parentNode = array.find((item) => item.id === node.parent);
    if (parentNode) {
      result = result.concat(findParent(parentNode, array));
    }
  }
  return result;
};

// return grid-cell css class according to number of items in a row module
export const gridCellSize = (numItems) => {
  let colMaxCount = 12;
  return `grid-cell--${colMaxCount / numItems}`;
};

// return object with html key to be used with React's dangerouslySetInnerHTML()
export const createMarkup = (markup) => {
  return { __html: markup };
};
