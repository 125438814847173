export default {
  en: {
    "Language Switch": "Language Switch",
    Login: "Login",
  },
  jp: {
    "Language Switch": "Japanese Translation",
    Login: "Login",
  },
};
