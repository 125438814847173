import React from "react"
import GridContainer from "@components/gridContainer";
import { Paragraph as ParagraphWrapless } from "@components/paragraph";
import { Image as ImageWrapless } from "@components/image";
import { Headline as HeadlineWrapless } from "@components/headline";
import { RelatedContent as RelatedContentWrapless }  from "@components/relatedContent";

import './category.scss'

const CategoryImages = (props) => {
  if (props.images.length > 1) {
    return (
      <>
      {props.images.map((item, index) => {
        return (
        <div className="grid-cell--3" key={index}>
          <div className="category-ImageWrapper" key={index + "i"}>
            <ImageWrapless src={item.image} caption={item.caption} alt={item.alt} border={item.addborder}></ImageWrapless>
          </div>
        </div>
          )
        })}
      </>
    )
  }
  return (
    <div className="grid-cell--6" key={"image"}>
      {props.images.map((item, index) => {
        return (
          <div className="category-ImageWrapper" key={index}>
            <ImageWrapless src={item.image} caption={item.caption} alt={item.alt} border={item.addborder} key={index}></ImageWrapless>
          </div>
        )
      })}
    </div>
  )
}

const RelatedPages = (props) => {
  return (
    <div className="grid-cell--6">
      {props.related_pages.map((item, index) => {
        return (
          <div className="category-relatedWrapper" key={index}>
            <RelatedContentWrapless related_post={item.related_post}></RelatedContentWrapless>
          </div>
        )
      })}
    </div>
  )
}

export const Category = (props) => {
  return (
    <>
      <div className={`category${props.modifier ? ` ${props.modifier}` : ''}`}>
        <div className="grid-container">

          <CategoryImages images={props.images}/>
          <RelatedPages related_pages={props.related_pages} />

        </div>
      </div>
    </>
  )
}

export default GridContainer(Category)