import React from "react"
import GridContainer from "@components/gridContainer";
import { Headline as HeadlineWrapless } from "@components/headline";
import { Paragraph as ParagraphWrapless } from "@components/paragraph";
import { Slider as SliderWrapless } from "@components/slider";

import './sliderText.scss'

export const SliderText = (props) => {

  if (props.add_slider) {
    return (
      <>
      <div className={`sliderText${props.modifier ? ` ${props.modifier}` : `` }${props.slider_left ? ` sliderText--sliderLeft` : ''}${props.add_slider ? " sliderText--secondSlider" : ""}`}>
        <div className="grid-container">
          <div className="grid-cell--4">
            <HeadlineWrapless text={props.Headline} priority="3"></HeadlineWrapless>
            <ParagraphWrapless text={props.text}></ParagraphWrapless>
          </div>
          <div className="grid-cell--4">
            <SliderWrapless slides={props.slider}></SliderWrapless>
          </div>
          <div className="grid-cell--4">
            <SliderWrapless slides={props.second_slider}></SliderWrapless>
          </div>
        </div>
      </div>
      </>
    )
  }
  if (props.add_placeholder) {
    return (
      <>
      <div className={`sliderText${props.modifier ? ` ${props.modifier}` : `` }${props.slider_left ? ` sliderText--sliderLeft` : ''}${props.add_slider ? " sliderText--secondSlider" : ""}`}>
        <div className="grid-container">
          <div className="grid-cell--4">
            <HeadlineWrapless text={props.Headline} priority="3"></HeadlineWrapless>
            <ParagraphWrapless text={props.text}></ParagraphWrapless>
          </div>
          <div className="grid-cell--4">
            <SliderWrapless slides={props.slider}></SliderWrapless>
          </div>
          <div className="grid-cell--4">

          </div>
        </div>
      </div>
      </>
    )
  }
  return (
    <>
      <div className={`sliderText${props.modifier ? ` ${props.modifier}` : props.slider_left ? ` sliderText--sliderLeft` : ''}`}>
        <div className="grid-container">
          <div className="grid-cell--6">
            <HeadlineWrapless text={props.Headline} priority="3"></HeadlineWrapless>
            <ParagraphWrapless text={props.text}></ParagraphWrapless>
          </div>
          <div className="grid-cell--6">
            <SliderWrapless slides={props.slider}></SliderWrapless>
          </div>
        </div>
      </div>
    </>
  )
}

export default GridContainer(SliderText)