import React from "react";
import { Link } from "react-router-dom";

import { getPageFromSitemap } from "@shared/app";
import GridContainer from "@components/gridContainer";

import "./startpageHero.scss";

export const StartpageHero = (props) => {
  const page = getPageFromSitemap(props.button.id);

  return (
    <>
      <div className="startpageHero">
        <img
          src={props.image.sizes.custom_2400}
          className="startpageHero-image"
        />
        <div className="startpageHero-contentWrapper">
          <div className="startpageHero-content">
            <div className="startpageHero-headline">{props.headline}</div>
            <div className="startpageHero-text">{props.text}</div>
            {page && (
              <div className="startpageHero-buttonWrapper">
                <Link
                  to={page.url}
                  target={props.button.target ? "props.button.target" : ""}
                  className="startpageHero-button"
                >
                  {props.button.caption}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GridContainer(StartpageHero);
