import React from 'react'
import GridContainer from '@components/gridContainer'
import { Image as ImageWrapless } from "@components/image";

import './comparison.scss'

export const Comparison = (props) => {
  return (
    <>
      <div className={`comparison${props.modifier ? ` ${props.modifier}` : props.image_left ? ` imageText--imageLeft` : ''}`}>
        <div className="grid-container">
          <div className="grid-cell--6 comparison-image">
            <ImageWrapless modifier="image--small-caption" imgSrc={props.left.image} caption={props.left.text} alt={props.left.text} border={props.left.border}></ImageWrapless>
          </div>
          <div className="grid-cell--6 comparison-image">
            <ImageWrapless modifier="image--small-caption" imgSrc={props.right.image} caption={props.right.text} alt={props.right.text} border={props.right.border}></ImageWrapless>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-cell--12">
            <hr className="comparison-line"></hr>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-cell--6 comparison-text comparison-text--left">
            {props.left.text}
          </div>
          <div className="grid-cell--6 comparison-text comparison-text--right">
            {props.right.text}
          </div>
        </div>
      </div>
    </>
  )
}

export default GridContainer(Comparison)