import React from "react";
import { Link } from "react-router-dom";

import { getPageFromSitemap } from "@shared/app";

import GridContainer from "@components/gridContainer";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";

import "./relatedContent.scss";
import { url } from "@shared/app/routes";

export const RelatedContent = (props) => {
  const page = getPageFromSitemap(props.related_post.ID);
  const parentPage = page ? getPageFromSitemap(page.parent) : false;

  return (
    <>
      {page ? (
        <div
          className={`relatedContent${
            props.modifier ? ` ${props.modifier}` : ""
          }`}
        >
          <Link className="relatedContent-link" to={page.url}>
            {parentPage && (
              <span className="relatedContent-category">
                {parentPage.title}
              </span>
            )}
            <div className="relatedContent-title">
              {page.title}
              <ArrowIcon className="relatedContent-arrowIcon" />
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default GridContainer(RelatedContent);
