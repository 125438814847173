import React from "react";
import { Link } from "react-router-dom";

import { getPageFromSitemap } from "@shared/app";
import GridContainer from "@components/gridContainer";
import { gridCellSize } from "@shared/utilities/helper";

import "./startpageTeaser.scss";

export const StartpageTeaser = (props) => {
  const page = getPageFromSitemap(props.card_link);
  return (
    <>
      <div className="startpageTeaser">
        <div className="grid-wrapper">
          <div className="grid-container">
            <div className="grid-cell--12">
              <div className="startpageTeaser-headline">{props.headline}</div>
              <div className="startpageTeaser-container grid-container">
                <div className="startpageTeaser-cardWrapper grid-cell--8">
                  {props.image && page.url && (
                    <Link to={page.url} className="startpageTeaser-card">
                      <div className="startpageTeaser-imageWrapper">
                        <img
                          src={props.image.sizes.custom_2400}
                          className="startpageTeaser-image"
                        />
                      </div>
                      <div className="startpageTeaser-cardTitle">
                        {props.card_title}
                      </div>
                    </Link>
                  )}
                </div>
                <div className="startpageTeaser-text grid-cell--4">
                  {props.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GridContainer(StartpageTeaser);
