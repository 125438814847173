import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { getPageFromSitemap } from "@shared/app";
import { flatten } from "@shared/utilities/helper";
import { url } from "@shared/app/routes";

import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg";

import "./menu.scss";
import { findParent } from "../../shared/utilities/helper";

const easingProps = (out = false) => ({
  transition: {
    type: "tween",
    duration: out ? 0.25 : 0.35,
    delay: out ? 0 : 0.2,
    ease: out ? [0.42, 0.005, 0.79, 0.57] : [0.06, 0.18, 0.065, 1],
  },
});

const CategoryItem = ({ id, title, active, children, ...rest }) => (
  <motion.div
    layoutId={`category-${id}`}
    className={`menu-main menu-item ${active ? " is-active" : ""}`}
    initial="hidden"
    animate="visible"
    exit="hidden"
    variants={{
      visible: { opacity: 1, x: 0, ...easingProps(false) },
      hidden: { opacity: 0, x: 0, ...easingProps(true) },
    }}
    {...rest}
  >
    {children}
    {title}
  </motion.div>
);

const Item = ({
  title,
  url,
  children = null,
  secondary = false,
  active = false,
  expanded = false,
  firstChild = false,
  lastChild = false,
  hasChildren = false,
  ...rest
}) => (
  <motion.div
    className={`menu-${secondary ? "secondary" : "primary"} menu-item ${
      active ? "is-active" : ""
    } ${firstChild ? "is-first-child" : ""} ${
      lastChild ? "is-last-child" : ""
    } ${hasChildren ? "has-children" : ""}
     ${expanded ? "is-expanded" : ""}
    `}
    layoutId={`item-${url}`}
    variants={{
      visible: { opacity: 1, x: 0, ...easingProps(false) },
      hidden: { opacity: 0, x: 100, ...easingProps(true) },
    }}
    {...rest}
  >
    <Link to={url}>
      <span>{title}</span>
    </Link>
    {children}
  </motion.div>
);

export default () => {
  const history = useHistory();

  const sitemap = useSelector((state) => state.sitemap);
  const currentPage = useSelector((state) => state.page);
  const currentLanguage = useSelector((state) => state.preferences.language);
  const menuItems = sitemap[currentLanguage].filter((item) => {
    return item.title != "Home";
  });

  const [category, setCategory] = useState();
  const [item, setItem] = useState();
  const [open, setOpen] = useState(false);
  const [offscreen, setOffscreen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const lastScrollPos = useRef(0);
  const { pathname } = useLocation();

  const findPageTitleById = (id) => {
    const page = menuItems.find((item) => item.id === id);
    return page !== undefined ? page.title : "";
  };

  const findPagesByCategory = (id) => {
    const openCategory = menuItems.find((item) => item.id === id);
    return openCategory !== undefined ? openCategory.children : [];
  };

  const handleClickItem = (item) => {
    if (item.children.length === 0) {
      history.push(item.url);
    } else {
      setCategory(item.id);
    }
  };

  const handleSwitchLanguage = (language) => {
    if (currentPage.meta) {
      const translations = currentPage.meta.translations;
      if (translations && translations[language]) {
        const translatedPage = getPageFromSitemap(
          translations[language],
          "id",
          language
        );
        history.push(translatedPage ? translatedPage.url : `/${language}/`);
      } else {
        history.push(`/${language}/`);
      }
    } else {
      history.push(`/${language}/`);
    }
  };

  const handleSearch = (target) => {
    setSearchQuery(target.value);
    const filteredSitemap = flatten(sitemap[currentLanguage]).filter((item) => {
      return item.title.search(new RegExp(target.value, "i")) != -1;
    });
    setSearchResults(filteredSitemap);
  };

  const handleHideSearch = (target) => {
    target.value = "";
    setTimeout(() => {
      setSearchQuery(false);
      setSearchResults([]);
    }, 1000);
  };

  useEffect(() => {
    let updatedCategory = null;
    let activeItem = undefined;
    flatten(menuItems).forEach((item) => {
      if (item.url === pathname) {
        activeItem = item;
      }
    });
    if (activeItem) {
      const parentNodes = findParent(activeItem, flatten(menuItems));
      parentNodes.forEach((node) => {
        if (
          node.children.length === 0 ||
          (node.level === 1 && node.children.length > 0)
        ) {
          setItem(node.url);
        }
        if (node.parent === 0 && node.children.length > 0) {
          updatedCategory = node.id;
        }
      });
    }
    setCategory(updatedCategory);
  }, [currentLanguage, pathname]);

  useEffect(() => {
    const handler = () => {
      const delta = window.pageYOffset - lastScrollPos.current;
      if (delta > 16 && !offscreen) {
        if (!open) setOffscreen(true);
      } else if (delta < 0 && offscreen) {
        setOffscreen(false);
      }
      lastScrollPos.current = window.pageYOffset;
    };

    window.addEventListener("scroll", handler, { passive: true });

    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, [offscreen, setOffscreen, open]);

  return (
    <>
      <div className={`menu-mobile${offscreen ? " menu-mobile--hidden" : ""}`}>
        <LogoIcon className="menu-logoIcon" />
      </div>
      <div className={`menu${open ? " menu--open" : ""}`}>
        <div className="menu-logo">
          <Link className="menu-logoLink" to={`/${currentLanguage}/`}>
            <LogoIcon className="menu-logoIcon" />
          </Link>
        </div>
        <div className="menu-items">
          <div className="menu-search menu-item">
            <SearchIcon className="menu-searchIcon menu-icon" />
            <input
              type="text"
              placeholder="Search"
              className="menu-searchField"
              onKeyUp={(e) => handleSearch(e.target)}
              onBlur={(e) => handleHideSearch(e.target)}
            />
          </div>
          {searchQuery != false && (
            <div className="menu-searchOverlay">
              <div className="menu-searchResults">
                {searchResults.map((item) => (
                  <Link to={item.url} className="menu-searchResult">
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {menuItems && (
            <div className="menu-layers">
              <div className="menu-layer">
                <AnimateSharedLayout>
                  <AnimatePresence>
                    {category ? (
                      <CategoryItem
                        id={category}
                        key={`category-${category}`}
                        onClick={() => setCategory(null)}
                      >
                        <ChevronLeftIcon className="menu-chevronIcon menu-icon" />
                        <span>{findPageTitleById(category)}</span>
                      </CategoryItem>
                    ) : (
                      menuItems.map((item) => (
                        <CategoryItem
                          id={item.id}
                          active={item.url === pathname}
                          key={`category-${item.id}`}
                          onClick={() => handleClickItem(item)}
                        >
                          {item.children.length > 0 && (
                            <ChevronLeftIcon className="menu-chevronIcon menu-icon menu-icon--rotate" />
                          )}
                          <span>{item.title}</span>
                        </CategoryItem>
                      ))
                    )}
                  </AnimatePresence>
                </AnimateSharedLayout>
              </div>
              <div className="menu-layer menu-layer--secondary">
                <AnimateSharedLayout>
                  <AnimatePresence>
                    {category ? (
                      <motion.ul
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={{
                          visible: {
                            opacity: 1,
                            transition: {
                              when: "beforeChildren",
                            },
                          },
                          hidden: {
                            opacity: 0,
                            transition: {
                              when: "afterChildren",
                            },
                          },
                        }}
                      >
                        {findPagesByCategory(category).map(
                          ({ title, url, children }) => (
                            <Fragment key={url}>
                              <Item
                                title={title}
                                url={url}
                                active={pathname === url}
                                expanded={item === url && children.length > 0}
                                hasChildren={children.length > 0}
                                onClick={() => {
                                  setItem(url);
                                  if (children.length === 0) setOpen(false);
                                }}
                              >
                                {children.length > 0 && item !== url && (
                                  <ChevronDownIcon className="menu-chevronIcon menu-icon menu-icon--right" />
                                )}
                                {children.length > 0 && item === url && (
                                  <ChevronDownIcon className="menu-chevronIcon menu-icon menu-icon--right menu-icon--up" />
                                )}
                              </Item>
                              {item === url
                                ? children.map(({ title, url }, index) => (
                                    <Item
                                      title={title}
                                      url={url}
                                      key={url}
                                      firstChild={index === 0}
                                      lastChild={index === children.length - 1}
                                      active={pathname === url}
                                      secondary
                                      onClick={() => setOpen(false)}
                                    />
                                  ))
                                : null}
                            </Fragment>
                          )
                        )}
                      </motion.ul>
                    ) : null}
                  </AnimatePresence>
                </AnimateSharedLayout>
              </div>
            </div>
          )}
        </div>
        <div className="menu-languageSwitch">
          {["en", "jp"].map((language) => (
            <span
              key={language}
              onClick={(event) => handleSwitchLanguage(language)}
              className={`menu-languageSwitchItem ${
                currentLanguage === language ? " is-active" : ""
              }`}
            >
              {language}
            </span>
          ))}
        </div>
      </div>
      <div
        className={`menu-burger${open ? " menu-burger--open" : ""}${
          offscreen ? " menu-burger--hidden" : ""
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className="menu-burgerBar"></div>
      </div>
    </>
  );
};
