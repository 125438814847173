import React from 'react'
import GridContainer from '@components/gridContainer'
import { createMarkup } from "@shared/utilities/helper";

import './richText.scss'

export const RichText = (props) => {
  return (
    <>
      <div className={`richText${props.modifier ? ` ${props.modifier}` : ''}`} dangerouslySetInnerHTML={createMarkup(props.text)}></div>
    </>
  )
}

export default GridContainer(RichText)
