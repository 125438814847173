import React from 'react'
import GridContainer from '@components/gridContainer'
import { Slider as SliderWrapless } from "@components/slider";
import { gridCellSize } from "@shared/utilities/helper";

import './sliderRow.scss'

export const SliderRow = (props) => {
  const numSliders = props.sliders.length

  // set grid cell width according to number of sliders in row
  const gridCellClass = gridCellSize(numSliders)

  return (
    <>
      <div className={`sliderRow${props.modifier ? ` ${props.modifier}` : ''}`}>
        <div className="grid-container">
          {props.sliders.map((slider, index) => {
            return (
              <div className={gridCellClass} key={index}>
                <SliderWrapless slides={slider.slides}></SliderWrapless>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default GridContainer(SliderRow)