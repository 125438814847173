import React from 'react'
import GridContainer from '@components/gridContainer'
import { Image as ImageWrapless } from "@components/image";
import { gridCellSize } from "@shared/utilities/helper";

import './imageRow.scss'

export const ImageRow = (props) => {
  const numImages = props.images.length

  // set grid cell width according to number of images in row
  const gridCellClass = gridCellSize(numImages)

  return (
    <>
      <div className={`imageRow${props.modifier ? ` ${props.modifier}` : ''}`}>
        <div className="grid-container">
          {props.images.map((image, index) => {
            return (
              <div className={gridCellClass} key={index}>
                <ImageWrapless src={image.image} caption={image.caption} alt={image.alt} border={image.border}></ImageWrapless>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default GridContainer(ImageRow)