import React from "react"
import GridContainer from "@components/gridContainer";
import { Headline as HeadlineWrapless } from "@components/headline";
import { Paragraph as ParagraphWrapless } from "@components/paragraph";
import { Image as ImageWrapless } from "@components/image";

import './imageText.scss'

export const ImageText = (props) => {

  //const imageCellSize = {imageSize}

  if (props.add_image) {
    return (
      <>
      <div className={`imageText${props.modifier ? ` ${props.modifier}` : ``}${props.image_left ? ` imageText--imageLeft` : ''}${props.add_image ? " imageText--secondImage" : ""}`}>
        <div className="grid-container">
          <div className={`grid-cell--4`}>
            <HeadlineWrapless text={props.Headline} priority="3"></HeadlineWrapless>
            <ParagraphWrapless text={props.text}></ParagraphWrapless>
          </div>
          <div className={`grid-cell--4`}>
            <ImageWrapless imgSrc={props.image} caption={props.caption} alt={props.alt} border={props.border}></ImageWrapless>
          </div>
          <div className={`grid-cell--4`}>
            <ImageWrapless imgSrc={props.second_image.image} caption={props.second_image.caption} alt={props.second_image.alt} border={props.second_image.border}></ImageWrapless>
          </div>
        </div>
      </div>
      </>
    )
  }
  return (
    <>
      <div className={`imageText${props.modifier ? ` ${props.modifier}` : props.image_left ? ` imageText--imageLeft` : ''}`}>
        <div className="grid-container">
          <div className={`${props.image_size ? "grid-cell--4" : props.text_size ? "grid-cell--8" : "grid-cell--6"}`}>
            <HeadlineWrapless text={props.Headline} priority="3"></HeadlineWrapless>
            <ParagraphWrapless text={props.text}></ParagraphWrapless>
          </div>
          <div className={`${props.image_size ? "grid-cell--8" : props.text_size ? "grid-cell--4" : "grid-cell--6"}`}>
            <ImageWrapless imgSrc={props.image} caption={props.caption} alt={props.alt} border={props.border}></ImageWrapless>
          </div>
        </div>
      </div>
    </>
  )
}

export default GridContainer(ImageText)