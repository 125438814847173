import React from 'react'
import { Headline as HeadlineWrapless } from "@components/headline";
import GridContainer from '@components/gridContainer'
import { RichText as RichTextWrapless } from "@components/richText";
import { createMarkup } from "@shared/utilities/helper";

import './formatsTable.scss'

export const FormatsTable = (props) => {
  let mytable = props.rows

  const Row = (props) => {
    return (
      <div className="formatsTable-row">
        <div className="grid-container">
          <div className="formatsTable-rowLeft grid-cell--6"><RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.textleft}></RichTextWrapless></div>
          <div className="formatsTable-rowRight grid-cell--6"><RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.textright}></RichTextWrapless></div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={ "formatsTable" + ( props.modifier ? ' table--' + props.modifier : '') } >
        <HeadlineWrapless priority="3" text={props.headline} modifier={'formatsTable-headline'} />
        {props.richtext &&
          <div className="table-richtext">{props.richtext}</div>
        }
        {props.rows.map((row, i) => {
          return <Row textleft={row.row_left} textright={row.row_right} key={i}/>
        })}
      </div>
    </>
  )
}

export default GridContainer(FormatsTable)