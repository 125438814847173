import React from 'react'
import GridContainer from '@components/gridContainer'
import { RichText as RichTextWrapless } from "@components/richText";

import './paragraph.scss'

export const Paragraph = (props) => {
  return (
    <>
      <RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.text}></RichTextWrapless>
    </>
  )
}

export default GridContainer(Paragraph)
