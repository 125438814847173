import React from "react";
import { Link } from "react-router-dom";

import { getPageFromSitemap } from "@shared/app";
import GridContainer from "@components/gridContainer";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";

import "./readNext.scss";

export const ReadNext = (props) => {
  const prevPage = props.prevnext.previous
    ? getPageFromSitemap(props.prevnext.previous.id)
    : false;
  const nextPage = props.prevnext.next
    ? getPageFromSitemap(props.prevnext.next.id)
    : false;

  return (
    <>
      <div className={`readNext${props.modifier ? ` ${props.modifier}` : ""}`}>
        {prevPage ? (
          <Link
            className="readNext-link readNext-link--previous"
            to={`${prevPage.url}`}
          >
            <div className="readNext-arrowWrapper readNext-arrowWrapper--left">
              <ArrowIcon className="readNext-arrowIcon readNext-arrowIcon--left" />
            </div>
            <div className="readNext-text">
              <span className="readNext-category">{`Previous guideline`}</span>
              <span className="readNext-title">{prevPage.title}</span>
            </div>
          </Link>
        ) : (
          <div></div>
        )}
        {nextPage ? (
          <Link
            className="readNext-link readNext-link--next"
            to={`${nextPage.url}`}
          >
            <div className="readNext-text">
              <span className="readNext-category">{`Next guideline`}</span>
              <span className="readNext-title">{nextPage.title}</span>
            </div>
            <div className="readNext-arrowWrapper readNext-arrowWrapper--right">
              <ArrowIcon className="readNext-arrowIcon readNext-arrowIcon--right" />
            </div>
          </Link>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default GridContainer(ReadNext);
