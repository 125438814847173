import React from "react";
import GridContainer from "@components/gridContainer";
import { RichText as RichTextWrapless } from "@components/richText";

import "./image.scss";

export const Image = (props) => {
  let imgSrc;
  if (props.imgSrc) {
    // imgSrc = props.src.sizes.custom_2400
    imgSrc = props.imgSrc;
  } else {
    imgSrc = props.src;
  }

  return (
    <>
      <div
        className={`image${props.modifier ? ` ${props.modifier}` : ""}${
          props.border ? ` image--border` : ""
        }`}
      >
        {imgSrc.sizes && (
          <picture>
            <source
              media="(min-width:900px)"
              srcSet={imgSrc.sizes.custom_2400}
            />
            <source
              media="(min-width:400px)"
              srcSet={imgSrc.sizes.custom_1200}
            />
            <source
              media="(min-width:300px)"
              srcSet={imgSrc.sizes.custom_600}
            />
            <img
              className="image-pic"
              src={imgSrc.sizes.custom_300}
              alt={props.alt}
            />
          </picture>
        )}
        {/* <img className="image-pic" src={imgSrc} alt={props.alt} /> */}
        {props.caption ? (
          <div className="image-caption">
            <RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.caption}></RichTextWrapless>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default GridContainer(Image);
