import { createStore, combineReducers } from "redux";

export const applicationDefaultState = {
  loading: false,
};

export const defaultPreferences = {
  authenticated: false,
  language: "en",
  translations: {},
};

export const pageDefaultState = {
  title: "",
};

export const setAuthenticated = (status) => {
  return {
    type: `SET_AUTHENTICATED`,
    payload: status,
  };
};

export const setLanguage = (language) => {
  return {
    type: `SET_LANGUAGE`,
    payload: language,
  };
};

export const setLoading = (loading) => {
  return {
    type: `SET_LOADING`,
    payload: loading,
  };
};

export const setCurrentPage = (page) => {
  return {
    type: `SET_CURRENT_PAGE`,
    payload: page,
  };
};

export const setSitemap = (sitemap) => {
  return {
    type: `SET_SITEMAP`,
    payload: sitemap,
  };
};

export const setTranslations = (translations) => {
  return {
    type: `SET_TRANSLATIONS`,
    payload: translations,
  };
};

const applicationReducer = (state = {}, action) => {
  switch (action.type) {
    case `SET_LOADING`:
      return Object.assign(
        {},
        {
          loading: action.payload,
        }
      );
      break;
    default:
      return state;
      break;
  }
};

const pageReducer = (state = {}, action) => {
  switch (action.type) {
    case `SET_CURRENT_PAGE`:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

const sitemapReducer = (state = {}, action) => {
  switch (action.type) {
    case `SET_SITEMAP`:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

const preferencesReducer = (state = {}, action) => {
  switch (action.type) {
    case `SET_AUTHENTICATED`:
      return Object.assign({}, state, {
        authenticated: action.payload,
      });
      break;
    case `SET_LANGUAGE`:
      return Object.assign({}, state, {
        language: action.payload,
      });
      break;
    case `SET_TRANSLATIONS`:
      return Object.assign({}, state, {
        translations: action.payload,
      });
      break;
    default:
      return state;
      break;
  }
};

const store = createStore(
  combineReducers({
    application: applicationReducer,
    page: pageReducer,
    preferences: preferencesReducer,
    sitemap: sitemapReducer,
  }),
  {
    application: applicationDefaultState,
    page: pageDefaultState,
    preferences: defaultPreferences,
    sitemap: {},
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
