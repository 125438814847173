import React from 'react'
import GridContainer from '@components/gridContainer'
import { RichText as RichTextWrapless } from "@components/richText";

import './textCards.scss'

export const TextCards = (props) => {
  return (
    <>
      <div className={`textCards${props.modifier ? ` ${props.modifier}` : ''}`}>
        {props.cards.map((card, index) => {
          return (
            <div className="textCards-card" key={index}>
              <div className="textCards-headline">{card.headline}</div>
              <RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={card.text}></RichTextWrapless>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default GridContainer(TextCards)
