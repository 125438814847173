import React from 'react'
import GridContainer from '@components/gridContainer'
import { gridCellSize } from "@shared/utilities/helper"
import { RichText as RichTextWrapless } from "@components/richText";

import './startpageFooter.scss'

const Contact = (props) => {
  return (
    <div className="startpageFooter-address grid-cell--4">
      <div className="startpageFooter-addressTitle">
        {props.name}
      </div>
      <div className="startpageFooter-addressText">
        <RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.info}></RichTextWrapless>
      </div>
    </div>
  )
}

const Contacts = (props) => {
  const items = props.items
  const allItems = items.map((contact, index) =>
    <Contact name={contact.name} info={contact.info} key={index} />
  );
  return (
    <>
      {allItems}
    </>
  )
}

export const StartpageFooter = (props) => {
  return (
    <>
      <div className="startpageFooter">
        <div className="grid-wrapper">
          <div className="grid-container">
            <div className="grid-cell--12">
              <div className="startpageFooter-headline">
              {props.headline}
              </div>
              <div className="startpageFooter-description">
              {props.text}
              </div>
              <div className="startpageFooter-addresses grid-container">

              <Contacts items={props.contacts} />

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className={`divider${props.modifier ? ` ${props.modifier}` : ''}${props.thickness ? ` divider--${props.thickness}` : ""}${props.color ? ` divider--${props.color}` : ""}`}></hr> */}
    </>
  )
}

export default GridContainer(StartpageFooter)