import React from 'react'
import GridContainer from '@components/gridContainer'

import './divider.scss'

export const Divider = (props) => {
  return (
    <>
      <hr className={`divider${props.modifier ? ` ${props.modifier}` : ''}${props.thickness ? ` divider--${props.thickness}` : ""}${props.color ? ` divider--${props.color}` : ""}`}></hr>
    </>
  )
}

export default GridContainer(Divider)